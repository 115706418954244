import React from 'react'
import { Col, Row } from 'react-bootstrap';
import ButtonAddRemove from '../../components/button_add_remove';
import { formatNumber } from '../../utils';
const API_URL = process.env.REACT_APP_API_URL

const ItemCartPayment = ({ item, onRemoveItem, onAddItem }) => {
    let { product, price, modifier_group } = item

    // let _default = modifier_group.filter(f => f.required === true)[0];

    // let _detail = modifier_group.filter(f => f.required !== true)
    // let _products = []

    // _detail.map((d, k) => {
    //     let { modifiers_product } = d;

    //     _products = _products.concat(modifiers_product.filter(p => p.selected === true))
    // })


    // if (_default !== undefined) {
    //     let { modifiers_product } = _default;

    //     let _f = modifiers_product.filter(f => f.selected === true)[0]

    //     price = _f.price
    // }

    return (
        <>
            <Row>
                <Col xs={4} className="px-1">
                    <img src={product.image} className="img-fluid" />
                </Col>
                <Col>
                    <Row>
                        <Col className='font-weight-bold'>{product.title}</Col>
                    </Row>
                    <Row>
                        <Col className='product-description-cart'>{product.description}</Col>
                    </Row>
                    {/* {_products.length > 0 ? <Row className='mt-1'>
                        <Col>
                            {_products.map(p => <Row key={p.id} className='border-top py-2'>
                                <Col xs={9} className='font-size-9'>{p.modifier.title}</Col>
                                <Col className='font-size-9'>$ {formatNumber(p.price)}</Col>
                            </Row>)}
                        </Col>
                    </Row> : null} */}
                    <Row>
                        <Col className='text-muted d-flex align-items-center' xs={5}>$ {formatNumber(price)}</Col>
                        <Col xs={7}>
                            <Row>
                                <Col>
                                    <ButtonAddRemove quantity={item.quantity} onRemove={() => onRemoveItem(item)} onAdd={() => onAddItem(item)} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className='py-0'>
                    <hr className='my-2' />
                </Col>
            </Row>
        </>
    )
}

export default ItemCartPayment;