import { getHeaders } from '../utils'
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching: 0,
    fetching_selected: 0,
    selected: {}
}


const CONFIG_PIZZA = "CONFIG_PIZZA"
const CONFIG_PIZZA_SUCCESS = "CONFIG_PIZZA_SUCCESS"
const GET_CONFIG_PIZZA = "GET_CONFIG_PIZZA"
const GET_CONFIG_PIZZA_SUCCESS = "GET_CONFIG_PIZZA_SUCCESS"
const RESET_STATUS = "RESET_STATUS"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CONFIG_PIZZA:
            return { ...state, fetching: 1 }
        case CONFIG_PIZZA_SUCCESS:
            return { ...state, fetching: 2, selected: action.payload }
        case GET_CONFIG_PIZZA:
            return { ...state, fetching_selected: 1 }
        case GET_CONFIG_PIZZA_SUCCESS:
            return { ...state, fetching_selected: 2, selected: action.payload }
        case RESET_STATUS:
            return { ...state, fetching_selected: 0, fetching: 0 }
        default:
            return state;
    }
}

export let resetStaus = () => dispatch => {
    dispatch({
        type: RESET_STATUS
    })
}

export let setConfigPizza = (config) => dispatch => {
    dispatch({
        type: CONFIG_PIZZA
    })

    localStorage.setItem("pizza", JSON.stringify(config))

    dispatch({
        type: CONFIG_PIZZA_SUCCESS,
        payload: config
    })

}
export let getConfigPizza = () => dispatch => {
    dispatch({
        type: GET_CONFIG_PIZZA
    })

    let row = JSON.parse(localStorage.getItem("pizza"))

    dispatch({
        type: GET_CONFIG_PIZZA_SUCCESS,
        payload: row
    })

}