import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Bolsa from '../images/bolsa.png'
import { formatNumber } from '../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const BottomMenu = ({ total, token }) => {
    return (
        <>
            <Row className='py-5'>
                <Col className='mt-5'>
                </Col>
            </Row>
            <Row className='fixed-bottom  footer-pizza'>
                <Col xs={12}>
                    <Row className=''>
                        <Col className='d-flex justify-content-center align-items-center'>
                            <Row className='div-totales'>
                                <Col className='text-orange'>Total</Col>
                                <Col className='text-grey'>{formatNumber(total || 0)}</Col>
                            </Row>
                        </Col>
                        <Col xs={3}>
                            <Link className='div-bolsa' to={`/carrito?t=${token}`}>
                                <img src={Bolsa} className='img-bolsa' />
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>

    )
}

export default BottomMenu;