let initialState = {
    fetching: 0,
    drawer: false,
    options: false,
    loader: false
}


const TOOGLE_DRAWER = "TOOGLE_DRAWER"
const TOOGLE_OPTIONS = "TOOGLE_OPTIONS"
const TOOGLE_LOADER = "TOOGLE_LOADER"


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case TOOGLE_DRAWER: {
            return { ...state, drawer: action.payload }
        }
        case TOOGLE_OPTIONS: {
            return { ...state, options: action.payload }
        }
        case TOOGLE_LOADER: {
            return { ...state, loader: action.payload }
        }
        default:
            return state;
    }
}

export let onToggleDrawable = () => (dispatch, getState) => {

    dispatch({
        type: TOOGLE_DRAWER,
        payload: !getState().alert.drawer
    })
}
export let onToggleOptions = () => (dispatch, getState) => {

    dispatch({
        type: TOOGLE_OPTIONS,
        payload: !getState().alert.options
    })
}

export let onToggleLoader = (status) => (dispatch, getState) => {

    dispatch({
        type: TOOGLE_LOADER,
        payload: status
    })
}