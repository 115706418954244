import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';

const ExpirationTokenPage = () => {
    return (
        <Container fluid className="vh-100 d-flex justify-content-center align-items-center">
            <Row className=''>
                <Col><h3>Pagina Expiro</h3></Col>
            </Row>
        </Container>
    )
}

export default ExpirationTokenPage;