import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import logo_madera from '../../images/logo_madera.png'


const LoaderPage = ({ show }) => {
    return (
        <Container fluid className={`loader ${show === true ? '' : 'd-none'}`}>
            <Row className='d-flex justify-content-center align-items-center w-100'>

                <Col lg={4} className="text-center">
                    <img src={logo_madera} className="img-fluid" />
                    <p className='text-center text-white'>Espere por Favor ...</p>
                </Col>
            </Row>
        </Container>
    )
}

export default LoaderPage;