import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo_madera from '../../images/logo_madera.png'
import burger from '../../images/burger.png'
const API_URL = process.env.REACT_APP_API_URL


const ItemCategory = ({ item, token }) => {

    let image = (item.photo === '') ? logo_madera : API_URL + item.photo;

    return (

        <Col xs={4} lg={3} className="px-0">
            <Link to={`category/${item.slug}?t=${token}`}>
                <Row className='my-3'>
                    <Col>
                        <Row className='d-flex justify-content-center'>
                            <Col xs={7}><img src={burger} className="img-fluid rounded-lg-10" /></Col>
                        </Row>
                        <Row className='my-1'>
                            <Col className='text-center text-gray-light font-size-14'>{item.title}</Col>
                        </Row>

                    </Col>
                </Row>
            </Link>
        </Col>



    )
}

export default ItemCategory;