import HomePage from "./page/home";
import { Switch, Route } from "react-router-dom";
import CartPage from "./page/cart";
import OptionsPage from "./page/options";
import DeliveryPage from "./page/delivery";
import NotFoundPage from "./page/not_found";
import CategoryPage from "./page/category";
import ProductPage from "./page/product";
import AddressPage from "./page/address";
import RoutesPrivate from "./RoutesPrivate";
import CheckoutPage from "./page/checkout";
import ConfirmationPage from "./page/checkout/confirmation.page";
import OrdersPage from "./page/orders";
import FavoritePage from "./page/favorite";
import LoginPage from "./page/login";
import RegisterPage from "./page/register";
import DiscountPage from "./page/discount";
import ConfirmationDiscountPage from "./page/discount/confirmation";
import ConfirmationCourtesyPage from "./page/discount/confirmation_courtesy";
import NetworkingPage from "./page/other/networking";
import CourtesyPage from "./page/discount/courtesy";
import PizzaPage from "./page/pizza/index.pizza";
import ItemsPizzaPage from "./page/pizza/items.pizza";
import ExpirationTokenPage from "./page/expiration/expiration_token";
// import PrivateRoute from './RoutesPrivate'

const Routes = () => {
  return (
    <Switch>
      {/* <Route exact path="/" component={OptionsPage} /> */}
      <Route exact path="/" component={HomePage} />
      {/* <RoutesPrivate exact path="/menu" component={HomePage} /> */}
      <Route exact path="/menu" component={HomePage} />
      <Route exact path="/carrito" component={CartPage} />
      <Route exact path="/delivery-takeout" component={DeliveryPage} />
      <Route exact path="/category/:slug" component={CategoryPage} />
      <Route exact path="/producto/:slug" component={ProductPage} />
      <Route exact path="/domicilio" component={AddressPage} />
      <Route exact path="/opciones-de-pago" component={CheckoutPage} />
      <Route exact path="/confirmacion" component={ConfirmationPage} />
      <Route exact path="/favoritos" component={FavoritePage} />
      <Route exact path="/direcciones" component={AddressPage} />
      <RoutesPrivate exact path="/pedidos" component={OrdersPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/registrate" component={RegisterPage} />
      <Route exact path="/descuento/:branch" component={DiscountPage} />
      <Route exact path="/descuento-confirmacion/:branch" component={ConfirmationDiscountPage} />

      <Route exact path="/cortesia/:branch" component={CourtesyPage} />
      <Route exact path="/arma-tu-pizza" component={PizzaPage} />
      <Route exact path="/arma-tu-pizza/:size" component={ItemsPizzaPage} />

      <Route exact path="/redes-sociales" component={NetworkingPage} />
      <Route exact path="/cortesia-confirmacion/:branch" component={ConfirmationCourtesyPage} />
      <Route exact path="/expirado" component={ExpirationTokenPage} />
      <Route path="*" exact={true} component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
