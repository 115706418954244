import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { FcCheckmark } from "react-icons/fc";
import { onToggleLoader } from '../../redux/alertDuck'
import { connect } from 'react-redux';
import { getValidateToken } from '../../redux/userDuck'


const ConfirmationPage = ({ onToggleLoader, getValidateToken, fetching_validate, data }) => {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token_web = params.get('t');

    const [phone, setPhone] = useState({})

    useEffect(() => {
        getValidateToken(token_web)
    }, [token_web])

    useEffect(() => {
        if (fetching_validate === 2) {
            setPhone(data)
        }
    }, [fetching_validate])

    useEffect(() => {
        onToggleLoader(false)
    }, [])


    return (
        <Container>
            <Row className='text-center space-confirmation'>
                <Col><h3>Gracias, por favor redirijete a WhatsApp</h3></Col>
            </Row>
            <Row className='my-5 text-center'>
                <Col>
                    <FcCheckmark size={150} />
                </Col>
            </Row>
            <Row className='text-center'>
                <Col>
                    <a href={`https://api.whatsapp.com/send?phone=${phone.bot}`} className="btn btn-success">Volver a WhatsApp</a>
                </Col>
            </Row>

        </Container>
    )
}

const mapsState = (props) => {
    let { user } = props

    return {
        fetching_validate: user.fetching_validate,
        data: user.data
    }
}

export default connect(mapsState, { onToggleLoader, getValidateToken })(ConfirmationPage);