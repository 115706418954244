import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { connect } from 'react-redux';
import { getCart, getTypeMethods, onModifyItem, onCreateOrder } from '../../redux/cartDuck'
import { onToggleLoader } from '../../redux/alertDuck'
import { onLogin } from '../../redux/userDuck'
import { formatNumber } from '../../utils';
import ItemCartPayment from './item.cart.payment';
import ItemPayment from './item.payment';
import { SocketContext } from '../../context/SocketContext'
import { useHistory } from 'react-router-dom';
import { getValidateToken } from '../../redux/userDuck'


const CheckoutPage = ({ getCart, cart, fetching_list, getTypeMethods, fetching_payment, data_payment, branch_office, onModifyItem, fetching_modify,
    loggedIn, onLogin, errors, onToggleLoader, onCreateOrder, fetching_order, getValidateToken, fetching_validate, total }) => {
    const [listCart, setListCart] = useState([])
    const [listPayment, setListPayment] = useState([])
    const [form, setForm] = useState({ observation: '', method_payment: 0 })
    const history = useHistory();
    const [error, setErrors] = useState(false)

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token_web = params.get('t');

    useEffect(() => {
        getValidateToken(token_web)
    }, [token_web])


    useEffect(() => {
        if (fetching_validate === 2) {
            getCart(token_web)
        }

        if (fetching_validate === 3) {
            history.replace("/expirado")
        }
    }, [fetching_validate])


    useEffect(() => {
        if (fetching_order === 2) {
            history.push(`/confirmacion?t=${token_web}`)
        }
    }, [fetching_order])


    useEffect(() => {
        if (fetching_list === 2) {
            setListCart(cart.detail)
        }
    }, [fetching_list])

    useEffect(() => {
        if (fetching_payment === 2) {
            setListPayment(data_payment)
        }
    }, [fetching_payment])

    useEffect(() => {
        getTypeMethods()
    }, [])

    const updateField = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const onCreateOrderTmp = () => {

        onToggleLoader(true)
        let _form = form
        let payment = listPayment.filter(row => row.selected === true)[0];
        _form.method_payment = payment
        onCreateOrder(_form, token_web);
    }

    const onChangePayment = (item) => {

        let _pay = listPayment.map(pay => {
            if (pay.id === item.id) {
                pay.selected = true;
            } else {
                pay.selected = false;
            }

            return pay
        })

        setListPayment(_pay)

    }

    const onRemoveItem = (item) => {
        item.quantity = item.quantity - 1;
        onModifyItem(token_web, item)
    }

    const onAddItem = (item) => {
        item.quantity = item.quantity + 1;
        onModifyItem(token_web, item)
    }

    return (
        <Container>
            <Row className='fixed-top border-bottom d-flex align-items-center bg-white mx-0 my-0'>
                <Col className='text-center pt-2  align-items-center d-flex justify-content-center mx-0'>
                    <h4>Checkout</h4>
                </Col>
            </Row>
            <Row className='mt-5 pt-4 pb-3'>
                <Col><b>Mi Pedido</b></Col>
            </Row>
            <Row>
                <Col>
                    {listCart.map((row, k) => <ItemCartPayment item={row} key={k} onRemoveItem={onRemoveItem} onAddItem={onAddItem} />)}
                </Col>
            </Row>

            <Row className='pt-4 d-flex justify-content-center'>
                <Col className='bg-white rounded-10'>
                    <Row className='pt-3'>
                        <Col xs={8}><b>¿Como deseas pagar?</b></Col>
                        <Col className='text-right justify-content-end d-flex align-items-center'>
                            <div className='title-options-required font-size-13'>Requerido*</div>
                        </Col>
                    </Row>
                    {listPayment.map(param => <ItemPayment item={param} key={param.id} onClick={onChangePayment} />)}
                </Col>
            </Row>
            {/* <Row className='my-3'>
                <Col><b>Recoger En:</b></Col>
            </Row>
            <Row className='my-3'>
                <Col>asdasd</Col>
                <Col xs={3}>
                    <button className='btn btn-sm btn-info'>Cambiar</button>
                </Col>
            </Row> */}
            <Row className='my-3'>
                <Col><b>Recomendación</b></Col>
            </Row>
            <Row className='my-3'>
                <Col>
                    <textarea className='form-control' name='observation' placeholder='¿Deseas agregar alguna observación?' onChange={updateField}></textarea>
                </Col>
            </Row>
            <Row className='my-3'>
                <Col><b>Resumen General</b></Col>
            </Row>

            <Row className='my-3'>
                <Col xs={9}><b>Total</b></Col>
                <Col>{formatNumber(total)}</Col>
            </Row>
            <Row className='my-5'>
                <Col></Col>
            </Row>

            <Row className='fixed-bottom'>
                <Col className='bg-black-9'>
                    <Row className=' justify-content-center py-3' onClick={() => history.goBack()}>
                        <Col className='ml-3' xs={2}>
                            <AiOutlineArrowLeft color='fff' size={25} />
                        </Col>
                        <Col>
                            <a href='#' className='text-white'>Volver</a>
                        </Col>
                    </Row>
                </Col>
                <Col className='bg-black-9 d-flex align-items-center justify-content-center'>
                    <button className='btn bg-golden text-white rounded-pill px-4 py-2' onClick={onCreateOrderTmp}>Enviar Pedido</button>
                </Col>
            </Row>
        </Container>
    )
}

const mapsState = (props) => {

    let { cart, header, user } = props
    return {
        cart: cart.data,
        fetching_list: cart.fetching_list,
        fetching_payment: cart.fetching_payment,
        fetching_order: cart.fetching_order,
        data_payment: cart.data_payment,
        branch_office: header.branch_office,
        fetching_modify: cart.fetching_modify,
        total: cart.total,
        loggedIn: user.loggedIn,
        errors: user.errors,
        fetching_validate: user.fetching_validate
    }
}

export default connect(mapsState, {
    getCart, getTypeMethods, onModifyItem, onLogin, onToggleLoader, onCreateOrder,
    getValidateToken
})(CheckoutPage);