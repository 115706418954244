import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import userReducer, { restoreSessionAction } from './userDuck';
import branchReducer, { getBranchOffice } from './branchOfficeDuck';
import categoryReducer from './categoryDuck';
import productReducer from './productDuck';
import alertReducer from './alertDuck';
import headerReducer, { getHeader } from './headerDuck';
import favoriteReducer from './favoriteDuck';
import cartReducer from './cartDuck';
import parameterReducer from './parameterDuck';
import discountReducer from './discountDuck';
import bannerReducer from './bannerDuck';
import pizzaReducer from './pizzaDuck';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let rootReducer = combineReducers({
    user: userReducer,
    branch_office: branchReducer,
    category: categoryReducer,
    product: productReducer,
    alert: alertReducer,
    header: headerReducer,
    favorite: favoriteReducer,
    cart: cartReducer,
    parameter: parameterReducer,
    discount: discountReducer,
    banner: bannerReducer,
    pizza: pizzaReducer,
})


export default function generateStore() {
    let store = createStore(rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    )

    restoreSessionAction()(store.dispatch)
    // getBranchOffice()(store.dispatch)
    // getHeader()(store.dispatch)

    // if(store.getState().user.loggedIn==true){
    //     getCart()(store.dispatch, store.getState)
    //     getCurrentUser()(store.dispatch, store.getState)
    //     getCategories()(store.dispatch,store.getState)
    // }

    return store
}
