import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import BottomMenu from '../../components/bottom_menu';
import table from '../../images/tabla.png'
import config from '../../images/config.png'
import { connect } from 'react-redux';
import { getConfigPizza, resetStaus } from '../../redux/pizzaDuck'
import './index.css'
import { formatNumber } from '../../utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import pizza_default from '../../images/pizza_default.png'
import portion from '../../images/portion.png'
import portion2 from '../../images/portion2.png'
import portion3 from '../../images/portion3.png'
import portion4 from '../../images/portion4.png'
import portion5 from '../../images/portion5.png'
import portion6 from '../../images/portion6.png'
import portion7 from '../../images/portion7.png'
import portion8 from '../../images/portion8.png'
import ItemModifier from './item.modifier';

const list_modifiers = [
    {
        id: 1,
        image: portion,
        title: "pizza 1"
    },
    {
        id: 2,
        image: portion2,
        title: "pizza 2"
    },
    {
        id: 3,
        image: portion3,
        title: "pizza 3"
    },
    {
        id: 4,
        image: portion4,
        title: "pizza 4"
    },
    {
        id: 5,
        image: portion5,
        title: "pizza 5"
    },
    {
        id: 6,
        image: portion6,
        title: "pizza 6"
    },
    {
        id: 7,
        image: portion7,
        title: "pizza 7"
    },
    {
        id: 8,
        image: portion8,
        title: "pizza 8"
    },
    {
        id: 9,
        image: pizza_default,
        title: "pizza 9"
    },
    {
        id: 10,
        image: pizza_default,
        title: "pizza 10"
    },
]


const ItemsPizzaPage = ({ getConfigPizza, fetching_selected, selected, resetStaus }) => {
    const [form, setForm] = useState({ title: '', flavors: '', price: 0, selected: [] })
    const [modifiers, setModifiers] = useState([])

    const history = useHistory();


    useEffect(() => {
        getConfigPizza()
    }, [])

    useEffect(() => {
        if (fetching_selected === 2) {
            setForm({
                ...form,
                ["title"]: selected.title,
                ["flavors"]: selected.flavors,
                ["image"]: selected.image,
                ["price"]: selected.price,
                ["portions_text"]: selected.portions_text,
                ["portions"]: selected.portions,
                ["multiplier"]: selected.multiplier,
            })
            resetStaus()
        }
    }, [fetching_selected])

    const onAddOK = () => {
        alert("onAddOK")
    }

    const onSelectFlavor = (item) => {
        let current = {};
        let _index = -1;

        for (let index = 0; index < form.multiplier.length; index++) {
            const element = form.multiplier[index];

            if (element.images.length === 0) {
                current = element;
                _index = index
                break
            }
        }


        if (_index !== -1) {
            for (let index = 0; index < current.portion; index++) {
                current.images.push(item)
            }
            let _form = form

            _form.multiplier[_index] = current
            setForm({
                ...form,
                "multiplier": _form.multiplier
            })
        } else {
            let selected = false
            let _index = -1;
            console.log('form', form);

            form.multiplier.map((m, ind) => {
                m.images.map((img, k) => {
                    if (img.id === item.id) {
                        selected = img
                        _index = ind;
                    }
                })
            })

            if (_index !== -1) {
                let _form = form

                _form.multiplier[_index] = { portion: _form.multiplier[_index].portion, images: [] }
                setForm({
                    ...form,
                    "multiplier": _form.multiplier
                })
            } else {
                alert("No puedes agregar")
            }
        }


        // let validate = modifiers.filter(d => d.id === item.id).length
        // if (validate === 0) {
        //     if (form.flavors > modifiers.length) {
        //         setModifiers([
        //             ...modifiers,
        //             item
        //         ])
        //     } else {
        //         alert(`Solo puedes Elegir ${form.flavors} Sabores`)
        //     }
        // } else {
        //     let filter = modifiers.filter(d => d.id !== item.id)
        //     setModifiers(filter)
        // }
    }


    const printSlice = () => {

        // if (modifiers.length > 0) {

        // let total = modifiers.length * multiplier[modifiers.length - 1];
        // total = Array.from(Array(total).keys());

        // return total.map((m, i) => <div key={i} className={`selected-pizza-item-${i + 1}`}
        //     style={{ backgroundImage: `url(${list_modifiers[0].image})` }}></div>)


        if (form.multiplier !== undefined) {
            let counter = 0;
            return form.multiplier.map((d, k) => {

                return d.images.map((img, key) => {
                    counter++
                    return <div key={key} className={`selected-pizza-item-${counter}`}
                        style={{ backgroundImage: `url(${img.image})` }}></div>

                })
            })
        }


        // return modifiers.map((m, i) => <div key={i} className={`selected-pizza-item-${i + 1}`}
        //     style={{ backgroundImage: `url(${m.image})` }}></div>)

        // }

    }

    return (
        <Container>
            <Row>
                <Col>
                    <div className='div-table'>
                        <img src={form.image} className='size-image pizza-selected table-pizza' />
                        <div className='selected-pizza'>
                            {printSlice()}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='text-center space-pizza-table' >
                <Col className='font-weight-bold'>{form.title} - {form.portions_text}</Col>
            </Row>
            <Row className='text-center'>
                <Col className='text-orange font-weight-bold font-size-18'>{form.flavors} Sabores</Col>
            </Row>
            <Row className='text-center'>
                <Col className='font-size-14'></Col>
            </Row>
            <Row className='text-center'>
                <Col className={`${form.flavors === modifiers.length ? 'text-success font-weight-bold font-size-20' : ''}`}>{modifiers.length} de {form.flavors}</Col>
            </Row>
            <Row className='d-flex justify-content-center mt-3'>
                <Col xs={2}>
                    <div className='div-bolsa' onClick={() => history.goBack()}>
                        <img src={config} className='img-bolsa' />
                    </div>
                </Col>
                <Col xs={5} className='d-flex justify-content-center align-items-center'>
                    <Row className='div-totales'>
                        <Col className='text-grey text-center font-weight-bold'>{formatNumber(form.price)}</Col>
                    </Row>
                </Col>
                <Col xs={2}>
                    <div className='div-bolsa' onClick={onAddOK}>
                        OK
                    </div>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col className='text-orange font-weight-bold  text-center'>Elige el sabor que deseas en la tabla</Col>
            </Row>
            <Row className=''>
                <Col className='text-center font-size-14'>Recuerda </Col>
            </Row>
            <Row className='mt-3'>
                {list_modifiers.map((d, k) => <ItemModifier isRight={(k) % 4} item={d} key={k}
                    onClick={() => onSelectFlavor(d)} modifiers={form.multiplier || []} />)}
            </Row>
            <BottomMenu />
        </Container>

    )
}

const mapsState = (props) => {
    let { pizza } = props

    return {
        fetching_selected: pizza.fetching_selected,
        selected: pizza.selected
    }
}

export default connect(mapsState, { getConfigPizza, resetStaus })(ItemsPizzaPage);