import React from 'react'
import { Col, Row } from 'react-bootstrap';

const ItemModifier = ({ item, onClick, modifiers, index, isRight }) => {

    let selected = false;
    modifiers.map(d => {
        let { images } = d

        images.map(im => {
            if (im.id === item.id) {
                selected = true;
            }
        })
    })

    return (
        <Col xs={3} onClick={onClick} className={`${selected ? (isRight === 0 || isRight === 1) ? 'active-pizza-left' : 'active-pizza-right' : ''}`}>
            <Row>
                <Col>
                    <img src={item.image} className='img-portion' />
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>{item.title}</Col>
            </Row>
        </Col>
    )
}

export default ItemModifier;