import React, { Fragment, useEffect } from 'react'
import BottomMenu from '../../components/bottom_menu';
import { Col, Container, Row } from 'react-bootstrap';
import personal1 from '../../images/PERSONAL_1.png'
import personal2 from '../../images/PERSONAL_2.png'
import mediana1 from '../../images/MEDIANA_1.png'
import mediana2 from '../../images/MEDIANA_2.png'
import mediana3 from '../../images/MEDIANA_3.png'
import completa1 from '../../images/COMPLETA_1.png'
import completa2 from '../../images/COMPLETA_2.png'
import completa3 from '../../images/COMPLETA_3.png'
import completa4 from '../../images/COMPLETA_4.png'
import completa5 from '../../images/COMPLETA_5.png'
import completa6 from '../../images/COMPLETA_6.png'
import './index.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { setConfigPizza, resetStaus } from '../../redux/pizzaDuck'
import { connect } from 'react-redux';


const config = [
    {
        title: "Pizza Personal",
        price_text: "* Desde $23",
        price: 23000,
        portions: 4,
        portions_text: "4 Porciones",
        link: "personal",
        items: [
            { image: personal1, type: 1, flavors: 2, multiplier: [{ portion: 4, images: [] }, { portion: 4, images: [] }] },
            { image: personal2, type: 2, flavors: 1, multiplier: [{ portion: 8, images: [] }] },
        ]
    },
    {
        title: "Pizza Mediana",
        price_text: "* Desde $42.5",
        price: 42500,
        portions_text: "8 Porciones",
        portions: 8,
        link: "mediana",
        items: [
            { image: mediana1, type: 2, flavors: 2, multiplier: [{ portion: 2, images: [] }, { portion: 6, images: [] }] },
            { image: mediana2, type: 3, flavors: 2, multiplier: [4, 4] },
            { image: mediana2, type: 3, flavors: 2, multiplier: [{ portion: 4, images: [] }, { portion: 4, images: [] }] },
            { image: mediana3, type: 4, flavors: 1, multiplier: [{ portion: 8, images: [] }] },
        ]
    },
    {
        title: "Pizza Completa",
        price_text: "* Desde $56",
        price: 56000,
        portions_text: "8 Porciones",
        portions: 8,
        link: "completa",
        items: [
            { image: completa1, type: 5, flavors: 4, multiplier: [{ portion: 2, images: [] }, { portion: 2, images: [] }, { portion: 2, images: [] }, { portion: 2, images: [] }] },
            { image: completa2, type: 6, flavors: 3, multiplier: [{ portion: 2, images: [] }, { portion: 2, images: [] }, { portion: 4, images: [] }] },
            { image: completa3, type: 7, flavors: 3, multiplier: [{ portion: 2, images: [] }, { portion: 3, images: [] }, { portion: 3, images: [] }] },
            { image: completa4, type: 8, flavors: 2, multiplier: [{ portion: 2, images: [] }, { portion: 6, images: [] }] },
            { image: completa5, type: 9, flavors: 2, multiplier: [{ portion: 4, images: [] }, { portion: 4, images: [] }] },
            { image: completa6, type: 10, flavors: 1, multiplier: [{ portion: 8, images: [] }] },
        ]
    },
]



const PizzaPage = ({ setConfigPizza, fetching, selected }) => {
    const history = useHistory();

    useEffect(() => {
        if (fetching === 2) {
            history.push(`/arma-tu-pizza/${selected.link}`)
        }
    }, [fetching])

    const onSelectOption = (item, link, title, price, portions, portions_text, multiplier) => {

        item.link = link
        item.title = title
        item.price = price
        item.portions = portions
        item.portions_text = portions_text
        item.multiplier = multiplier

        setConfigPizza(item)
    }

    return (
        <Container>

            <Row className='d-flex justify-content-center text-center'>
                <Col>
                    {config.map((d, k) => <Fragment key={k}>
                        <Row className='d-flex justify-content-center'>
                            <Col xs={10}><hr className='divider-pizza' /></Col>
                        </Row>
                        <Row className=''>
                            <Col className='font-weight-bold text-grey font-size-18'>
                                {d.title}{d.price_text}
                            </Col>
                        </Row>

                        <Row>
                            <Col className='text-orange font-weight-bold'>{d.portions_text}</Col>
                        </Row>
                        <Row className='d-flex justify-content-center text-center'>
                            {d.items.map((i, k) => <Col xs={4} key={k} className='px-0' onClick={() => onSelectOption(i, d.link, d.title, d.price, d.portions, d.portions_text, i.multiplier)}>
                                <Row>
                                    <Col><img src={i.image} className='img-pizza-config mt-2' /></Col>
                                </Row>
                                <Row>
                                    <Col><span className='text-muted font-size-13'>{i.flavors} {i.flavors === 1 ? `Sabor` : 'Sabores'}</span></Col>
                                </Row>
                            </Col>)}

                        </Row>
                    </Fragment>)}
                </Col>
            </Row>
            <BottomMenu />
        </Container>

    )
}

const mapsState = (props) => {
    let { pizza } = props
    return {
        fetching: pizza.fetching,
        selected: pizza.selected
    }
}

export default connect(mapsState, { setConfigPizza })(PizzaPage);