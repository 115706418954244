import { BrowserRouter, Route } from 'react-router-dom'

import Routes from './Routes';
import LoaderPage from './page/loader';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getValidateToken } from './redux/userDuck'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const App = ({ loader }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token_web = params.get('t');

  const history = useHistory()

  return (
    <BrowserRouter>
      <LoaderPage show={loader} />
      <Routes />
    </BrowserRouter>
  );
}
const mapsState = (props) => {
  let { alert, user } = props
  return {
    loader: alert.loader,

  }
}

export default connect(mapsState, {})(App);
