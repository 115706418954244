import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getCategories } from '../../redux/categoryDuck'
import { getBanners } from '../../redux/bannerDuck'

import { onResetChoose, getBranchOffice } from '../../redux/branchOfficeDuck'
import { getValidateToken } from '../../redux/userDuck'
import { getHeader } from '../../redux/headerDuck'
import { getCart } from '../../redux/cartDuck'
import ItemCategory from './item.categories';
import { useHistory } from 'react-router-dom';
import TemplateMenu from '../../template/menu.template';
import Arma from '../../images/arma.png'
import Flecha from '../../images/flecha.png'
import './index.css';
import BottomMenu from '../../components/bottom_menu';

const HomePage = ({ getCategories, categories, drawer, getHeader, onResetChoose, title, getBanners, getBranchOffice,
    fetching_list, data_banner, getValidateToken, getCart, total, fetching_validate }) => {
    const history = useHistory()

    const [typeOrder, setTypeOrder] = useState({ title: 'Recoger', type: 1 })

    const [listBanner, setListBanner] = useState([])


    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token_web = params.get('t');

    useEffect(() => {
        getValidateToken(token_web)
    }, [token_web])

    useEffect(() => {
        if (fetching_list === 2) {
            setListBanner(data_banner)
        }
    }, [fetching_list])
    useEffect(() => {
        if (fetching_validate === 2) {
            getBranchOffice(token_web)
            getCategories(token_web)
            getCart(token_web)
        }
        if (fetching_validate === 3) {
            history.replace("/expirado")
        }
    }, [fetching_validate])


    const onChangeType = () => {
        setTypeOrder({ type: typeOrder.type === 1 ? 2 : 1, title: typeOrder.type === 1 ? "Recoger" : 'Domicilio' })
    }

    const onMakingPizza = () => {
        history.push("/arma-tu-pizza")
    }


    return (
        <TemplateMenu>
            <Container fluid>
                <div className='my-2 container-banner' >
                    {listBanner.map((b, k) =>
                        <div className='item-banner' key={k} style={{ backgroundImage: `url(${b.real_image})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}></div>
                    )}
                </div>
                <Row className='d-flex justify-content-center py-3'>
                    <Col xs={10} className='making-pizza' onClick={onMakingPizza}>
                        <Row className='py-2'>
                            <Col className='d-flex justify-content-end align-items-center'>
                                <img src={Arma} className='img-arma' /> Arma tu Pizza
                            </Col>
                            <Col xs={3} className='d-flex justify-content-end align-items-center'>
                                <img src={Flecha} className='img-flecha' />
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row className='d-flex justify-content-center'>
                    <Col lg={10} xs={12} className="px-1 mx-1">
                        <Row className='mt-3 mx-1'>
                            {categories.map((row, k) => <ItemCategory item={row} key={k} token={token_web} />)}
                        </Row>
                    </Col>
                </Row>

                <BottomMenu total={total} token={token_web} />

            </Container>
        </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { banner, category, cart, user } = props

    return {
        categories: category.data,
        fetching_list: banner.fetching_list,
        data_banner: banner.data,
        total: cart.total,
        fetching_validate: user.fetching_validate

    }
}

export default connect(mapsState, { getCategories, getHeader, onResetChoose, getBanners, getValidateToken, getBranchOffice, getCart })(HomePage);