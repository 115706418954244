import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { getCategoriesSlug } from "../../redux/categoryDuck";

import { FaAngleLeft, FaShoppingCart } from "react-icons/fa";
import ItemProduct from "./item.product";
import { useHistory } from "react-router-dom";
import { formatNumber } from "../../utils";
import { Link } from "react-router-dom";
import { getCart } from '../../redux/cartDuck'

const API_URL = process.env.REACT_APP_API_URL;

const CategoryPage = ({ getCategoriesSlug, data_slug, fetching_slug, quantity, total,
  getCart, fetching_validate }) => {
  const { slug } = useParams();
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [image, setImage] = useState("");

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token_web = params.get('t');

  const history = useHistory();

  useEffect(() => {
    getCart(token_web)
  }, [token_web])

  useEffect(() => {
    if (fetching_validate === 2) {
      getCart(token_web)
    }
  }, [fetching_validate]);

  useEffect(() => {
    getCategoriesSlug(slug);
  }, [slug]);

  useEffect(() => {
    if (fetching_slug === 2) {
      setCategory(data_slug);
      setImage(data_slug.photo);
      setProducts(data_slug.products);
    }
  }, [fetching_slug]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <Container fluid className="h-100 mx-0 bg-grey">
      <Row>
        <Col className="mx-0 px-0">
          <div className="image-category" style={{ backgroundImage: `url(${image})` }}>
            <a href="#" id="back-category" onClick={() => goBack()}>
              <FaAngleLeft color="fff" size={30} />
            </a>
            <div id="title-category">{category.title}</div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="mx-0 ml-2 px-1">
          {products.map((row) => (
            <ItemProduct item={row} key={row.id} token={token_web} />
          ))}
        </Col>
      </Row>
      <Row className="my-5">
        <Col></Col>
      </Row>
      <Row className="fixed-bottom">
        <Col>
          <Row className="bg-black-9 py-1">
            <Col xs={4} className="my-3 text-white font-weight-bold ml-4 ">
              $ {formatNumber(total)}
            </Col>
            <Col className="text-white align-items-end justify-content-end d-flex mr-2">
              <Link className="btn-buttom-cart" to={`/carrito?t=${token_web}`}>
                <div className="btn-menu-main">
                  <Row className="py-2 justify-content-start align-items-center ml-1">
                    <Col xs={2}>
                      <FaShoppingCart />
                    </Col>
                    <Col xs={3}>
                      <div className="dot-quantity align-items-center d-flex justify-content-center">
                        <span className="text-center">{quantity}</span>
                      </div>
                    </Col>
                    <Col xs={6} className="px-1 text-center">
                      Ver pedido
                    </Col>
                  </Row>
                </div>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const mapsState = (props) => {
  let { user, category, cart } = props
  return {
    data_slug: category.data_slug,
    fetching_slug: category.fetching_slug,
    quantity: cart.quantity,
    total: cart.total,
    fetching_validate: user.fetching_validate
  };
};

export default connect(mapsState, { getCategoriesSlug, getCart })(
  withRouter(CategoryPage)
);
