import React from 'react'
import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DrawerSegment from '../components/drawer.segment';
import MenuSegment from '../components/menu.segment';
import OptionsBranch from '../components/options_branch';
import { onToggleDrawable, onToggleOptions } from '../redux/alertDuck'



const TemplateMenu = ({ children, drawer, onToggleDrawable, title, user, onToggleOptions }) => {

    const history = useHistory()

    const goToSeleccion = () => {
        onToggleOptions()
        // history.push("/delivery-takeout")
    }

    return (
        <>
            {/* <DrawerSegment drawer={drawer} onToggleDrawable={onToggleDrawable} user={user} /> */}
            <Container fluid className='px-0' >
                {/* <MenuSegment title={title} onToggleDrawable={onToggleDrawable} goToSeleccion={goToSeleccion} /> */}
                {children}
                <OptionsBranch />
            </Container >
        </>

    )
}

const mapsState = (props) => {
    const { alert, header, user } = props
    return {
        user: user,
        drawer: alert.drawer,
        title: header.title,
    }
}

export default connect(mapsState, { onToggleDrawable, onToggleOptions })(TemplateMenu);